import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/Exam.css';
import BeatLoader from 'react-spinners/BeatLoader';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AskMina from './AskMina';
import mixpanel from './mixpanel';  // Import the initialized Mixpanel instance
import useMediaQuery from '@mui/material/useMediaQuery';

const QUIZ_API_URL = "https://api.getmina.co/api/v1/panel/get-quiz-of-the-day";
const SUBMIT_QUIZ_API_URL = "https://api.getmina.co/api/v1/panel/submit-quiz-of-the-day";

function ExamOfTheDay() {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [responses, setResponses] = useState([]);
  const [content, setContent] = useState([]);
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [userSelectedAnswer, setUserSelectedAnswer] = useState(null);
  const [revealAnswer, setRevealAnswer] = useState(false);
  const [isAnswerRevealed, setIsAnswerRevealed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hintReceived, setHintReceived] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showAskMina, setShowAskMina] = useState(false);
  const [askMinaKey, setAskMinaKey] = useState(0);
  const [quizId, setQuizId] = useState(null); // State to store the quiz_id

  const hasFetchedQuestions = useRef(false);
  const hasTrackedSession = useRef(false); // Ref to track Mixpanel event

  const fetchQuestions = useCallback(async () => {
    try {
      setLoading(true);
      const jwt = localStorage.getItem('jwt');
      const requestBody = { level: localStorage.getItem('selectedLevel') };
      const response = await fetch(QUIZ_API_URL, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      console.log(data);
      setContent(data.questions.map(question => ({ ...question, selectedAnswer: null, isAnswered: false })));
      setQuizId(data.quiz_id); // Store the quiz_id in state
      setLoading(false);
      mixpanel.track('Quiz of the day session', {
        level: localStorage.getItem('selectedLevel'),
      });
    } catch (error) {
      setError("Error fetching questions");
      setLoading(false);
    }
  }, []);

  const fetchUserStatus = useCallback(async () => {
    try {
      if (!hasTrackedSession.current) { // Check if the session has already been tracked
        mixpanel.track('New Exam Session', {
          quiz: "quiz-of-the-day",
        });
        hasTrackedSession.current = true; // Set the ref to true
      }
    } catch (error) {
      console.error("Error fetching user status:", error);
    }
  }, []);

  useEffect(() => {
    if (!hasFetchedQuestions.current) {
      fetchQuestions();
      fetchUserStatus();
      hasFetchedQuestions.current = true;
    }
  }, [fetchQuestions, fetchUserStatus]);

  const handleAskMina = () => {
    setShowAskMina(true);
  };

  const handleSubmit = async () => {
    const requestBody = {
      quiz_id: quizId, // Use the quiz_id from the state
      responses: responses,
    };

    try {
      const jwt = localStorage.getItem('jwt');
      const response = await fetch(SUBMIT_QUIZ_API_URL, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${jwt}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to submit quiz: ${response.status} ${errorText}`);
      }

      alert("Quiz submitted successfully!");
      

      navigate(`/`);
    } catch (error) {
      console.error("Failed to submit quiz:", error.message);
    }
  };

  const handleAnswerSelection = (index) => {
    if (!isAnswerRevealed) {
      setUserSelectedAnswer(index);
      setRevealAnswer(false);

      const updatedContent = content.map((question, idx) =>
        idx === currentContentIndex ? { ...question, selectedAnswer: index } : question
      );
      setContent(updatedContent);
      const questionId = content[currentContentIndex]?.question_id;
      const selectedAnswer = content[currentContentIndex]?.choices.choices[index];

      const newResponses = responses.filter(response => response.question_id !== questionId);
      newResponses.push({ question_id: questionId, selected_answer: selectedAnswer });
      setResponses(newResponses);
    }
  };

  const handleRevealAnswer = () => {
    if (userSelectedAnswer === null) {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
      return;
    }

    if (isAnswerRevealed) {
      const nextContentIndex = currentContentIndex + 1;
      if (nextContentIndex < content.length) {
        setCurrentContentIndex(nextContentIndex);
        setUserSelectedAnswer(content[nextContentIndex]?.selectedAnswer);
        setRevealAnswer(content[nextContentIndex]?.isAnswered);
        setIsAnswerRevealed(content[nextContentIndex]?.isAnswered);
        setHintReceived(false); // Reset hint when moving to the next question
        setAskMinaKey(prevKey => prevKey + 1); // Force AskMina to re-render
      }
      if (isAnswerRevealed && currentContentIndex === content.length - 1) {
        handleSubmit();
      }
    } else {
      setUserSelectedAnswer(content[currentContentIndex]?.selectedAnswer);
      setRevealAnswer(true);
      setIsAnswerRevealed(true);

      const updatedContent = content.map((question, idx) =>
        idx === currentContentIndex ? { ...question, isAnswered: true } : question
      );
      setContent(updatedContent);
      mixpanel.track('Reveal Answer', {
        question_id: content[currentContentIndex]?.question_id,
        question_number: currentContentIndex + 1,
      });
    }
  };

  const handlePreviousQuestion = () => {
    if (currentContentIndex > 0) {
      const previousContentIndex = currentContentIndex - 1;
      setCurrentContentIndex(previousContentIndex);
      setUserSelectedAnswer(content[previousContentIndex]?.selectedAnswer);
      setRevealAnswer(true);
      setIsAnswerRevealed(true);
      setHintReceived(false); // Reset hint when moving to the previous question
    }
  };

  const handleBackButtonClick = () => {
    mixpanel.track('Cancel Exam', {
      
      exam_id: "quiz-of-the-day",
      question_id: content[currentContentIndex]?.question_id,
      question_number: currentContentIndex + 1,
    });
    navigate('/');
  };

  const formatText = (text) => {
    if (!text || text === '0') return ''; // Treat 0 as an empty string

    // Use a regular expression to split by spaces unless they are within parentheses
    return text.split(/(?<!\S)(\s{2,})(?!\S)(?!.*\))/g).map((part, index) => (
      <span key={index}>
        {part.trim().length === 0 ? <br /> : part}
      </span>
    ));
  };

  if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;

  if (error) {
    return <div>{error}</div>;
  }

  if (content.length === 0 || !content[currentContentIndex]) {
    return <div>No content available</div>;
  }

  const questionNumber = currentContentIndex + 1;
  const { script, question, instruction, choices, answer, hint, explanation, selectedAnswer, isAnswered } = content[currentContentIndex];

  return (
    <div className="Exam">
      <>
        <div className={`upper-bar `}>
          <div onClick={handleBackButtonClick} aria-label="back" className="back-button upper-left">
            <ArrowBackIosNewIcon  />
            Back
          </div>
          <div className="upper-center">Quiz of the Day</div>
          <div className="upper-right">Question {questionNumber}</div>
        </div>
        <div className="content">
          {isSmallScreen ? (
            <>
              <div className="divider"></div>
              <div className="right-section">
                {instruction && <p>{formatText(instruction)}</p>}
                <br></br>
                {script && (
                  <>
                    <div className="passage-box">
                      {formatText(script)}
                    </div>
                  </>
                )}
                <br></br>

                <div className="question-answer">
                  <div className="question-text">{formatText(question)}</div>
                  <div className="answer-section">
                    {choices.choices.map((choice, index) => (
                      <div
                        key={index}
                        className={`answer-option ${selectedAnswer === index ? 'selected' : ''} ${revealAnswer ? (choice === answer ? 'correct' : (selectedAnswer === index ? 'incorrect' : '')) : ''}`}
                        onClick={() => !isAnswered && handleAnswerSelection(index)}
                      >
                        {choice}
                      </div>
                    ))}
                  </div>
                  {hintReceived && hint && (
                    <div className="hint-box">
                      <strong>Hint:</strong> {hint}
                    </div>
                  )}
                  {isAnswerRevealed && explanation && (
                    <div className="explanation-box">
                      <strong>Explanation:</strong> {explanation}
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="left-section">
                {instruction && <p>{formatText(instruction)}</p>}
                {script && (
                  <>
                    <div className="passage-box">
                      {formatText(script)}
                    </div>
                  </>
                )}
                <div className="question-answer">
                  <div className="question-text">{formatText(question)}</div>
                  <div className="answer-section">
                    {choices.choices.map((choice, index) => (
                      <div
                        key={index}
                        className={`answer-option ${selectedAnswer === index ? 'selected' : ''} ${revealAnswer ? (choice === answer ? 'correct' : (selectedAnswer === index ? 'incorrect' : '')) : ''}`}
                        onClick={() => !isAnswered && handleAnswerSelection(index)}
                      >
                        {choice}
                      </div>
                    ))}
                  </div>
                  {hintReceived && hint && (
                    <div className="hint-box">
                      <strong>Hint:</strong> {hint}
                    </div>
                  )}
                  {isAnswerRevealed && explanation && (
                    <div className="explanation-box">
                      <strong>Explanation:</strong> {explanation}
                    </div>
                  )}
                </div>
              </div>
              <div className="divider"></div>
              <div className="right-section">
                <AskMina
                  hint={hint}
                  key={askMinaKey}
                  questionId={content[currentContentIndex]?.question_id}
                  questionnumber={currentContentIndex + 1}
                  initialChatHistory={[]}
                  onClose={() => setShowAskMina(false)}
                />
                {showAskMina && (
                  <AskMina
                    hint={hint}
                    key={askMinaKey}
                    questionId={content[currentContentIndex]?.question_id}
                    questionnumber={currentContentIndex + 1}
                    initialChatHistory={[]}
                    onClose={() => setShowAskMina(false)}
                  />
                )}
              </div>
            </>
          )}
        </div>
        <div className={`lower-bar `}>
          <div className="lower-left"></div>
          <div className="lower-right">
            <button className="previous-button" onClick={handlePreviousQuestion}>Previous</button>
            <button className="reveal-answer-button" onClick={handleRevealAnswer} disabled={userSelectedAnswer === null}>
              {isAnswerRevealed ? currentContentIndex === content.length - 1 ? "Finish" : "Next" : "Reveal Answer"}
            </button>
            {showPopup && <div className="popup">You need to pick an answer before the right answer can be revealed.</div>}
          </div>
        </div>
        {isSmallScreen && !showAskMina && (
          <button className="floating-ask-mina-button" onClick={handleAskMina}>Ask Mina</button>
        )}
      </>
      {showAskMina && isSmallScreen && (
        <div className="askmina-overlay">
          <AskMina
            questionId={content[currentContentIndex]?.question_id}
            questionnumber={currentContentIndex + 1}
            initialChatHistory={[]}
            onClose={() => setShowAskMina(false)}
          />
        </div>
      )}
    </div>
  );
}

export default ExamOfTheDay;
