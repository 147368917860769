import React, { useEffect, useState } from 'react';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './Home';
import StudyPlan from './StudyPlan';
import DiagnosticExam from './DiagnosticExam';
import Exam from './Exam';
import ExamOfTheDay from './ExamOfTheDay';
import MockExam from './MockExam';
import Login from './Login';
import Signup from './SignUp';
import EmailVerification from './EmailVerification';
import ReviewExam from './ReviewExam';
import ReviewPersonalizedExam from './ReviewPersonalized';
import ReviewMockExam from './ReviewMockExam';
import AskMina from './AskMina';
import AskMinaHome from './AskMinaHome';
import AdBlockDetector from './AdBlockDetector';
import ForgotPassword from './forgotpassword';
import ResetPassword from './resetpassword';
import DiagnosticExamResultsPage from './dashboard/DiagnosticExamsResultsPage'
import PersonalizedExamResultsPage from './dashboard/PersonalizedExamsResultsPage'


import UsersListPage from './dashboard/UsersListPage';

import DiagnosesExamDetail from './dashboard/DiagnosesExamDetail';
import CreateStudyPlan from './dashboard/CreateStudyPlan';
import UserExamsPage from './dashboard/UserExamsPage';
import DiagnosticExams from './dashboard/DiagnosticExams';





import './css/App.css';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import MyCheckoutComponent from './MyCheckoutComponent';
import PersonalizedExam from './PersonalizedExam';

const stripePromise = loadStripe('pk_test_51PCLezBQK9NeVKEQ9UZaCLagoap3lORt7iIs0j2mZAmnVBCHh1D3hYr8h9A7UlXVRG8w1PVdaA92qxLDZjOuLRCn002sOLsGHy');

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check for JWT in local storage
    const jwt = localStorage.getItem('jwt');
    setIsAuthenticated(!!jwt);
  }, []);

  if (!isAuthenticated) {
    // If not authenticated, redirect to login page without the topbar
    return (
      <Router>
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />

        
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <div className="App">
      <AdBlockDetector />
        <Routes>
          <Route path="/StudyPlan" element={<StudyPlan />} />
          <Route path="/" element={<Home />} />

          <Route path="/exam/:exam_id" element={<Exam />} />
          <Route path="/personalized-exam/:exam_id" element={<PersonalizedExam />} />

          <Route path="/diagnostic-exam/:exam_id" element={<DiagnosticExam />} />
          <Route path="/quiz-of-the-day/" element={<ExamOfTheDay />} />
          <Route path="/mockexam/:exam_id" element={<MockExam />} />
          <Route path="/askmina" element={<AskMina />} />
          <Route path="/askminahome" element={<AskMinaHome />} />
          <Route path="/review-exam/:exam_id" element={<ReviewExam />} />
          <Route path="/review-personalized/:exam_id" element={<ReviewPersonalizedExam />} />
          <Route path="/review-mockexam/:exam_id" element={<ReviewMockExam />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/exam/:examId/users" element={<UsersListPage />} />
          <Route path="/person/:id/exam-results" element={<DiagnosesExamDetail />} />
          
          <Route path="/DiagnosticExams" element={<DiagnosticExams />} />
          <Route path="/user/:userId/exams" element={<UserExamsPage />} />
          <Route path="/diagnostic-exam-results/:userId/:examId" element={<DiagnosticExamResultsPage />} />
          <Route path="/personalized-exam-results/:userId/:examId" element={<PersonalizedExamResultsPage />} />


          
          
          <Route path="/create-study-plan/:userId" element={<CreateStudyPlan />} />
          <Route path="/checkout" element={
            <Elements stripe={stripePromise}>
              <MyCheckoutComponent />
            </Elements>
          } />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
