// src/SignUp.js
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import BeatLoader from 'react-spinners/BeatLoader';
import Mina from './assets/images/mina.png';
import { useNavigate } from 'react-router-dom';
import './css/Login.css'; // Make sure you have this file

const defaultTheme = createTheme();

export default function SignUp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const payload = {
      email: data.get('email'),
      firstname: data.get('firstName'),
      lastname: data.get('lastName'),
      level: "N3",
      password: data.get('password'),
      username: data.get('username')
    };

    if (!payload.email || !payload.firstname || !payload.lastname || !payload.password || !payload.username) {
      setErrorMessage('All fields are required.');
      return;
    }

    setLoading(true);
    setErrorMessage('');

    try {
      const response = await fetch('https://api.getmina.co/api/v1/auth/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      const responseData = await response.json();
      setLoading(false);

      if (response.ok) {
        localStorage.setItem('jwt', responseData.access);
        console.log('Registration successful:', responseData);
        navigate('/email-verification'); // Redirect to email verification page
      } else {
        setErrorMessage(responseData.message || 'Failed to register');
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('Error during registration: ' + error.message
      );
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={Mina} alt="Mina Logo" className="mina" />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          {errorMessage && (
            <Alert severity="error" sx={{ width: '100%', marginTop: 2 }}>
              {"Sign up failed, try again"}
            </Alert>
          )}
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#3E00EF',
                color: 'white',
                borderRadius: '5px',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#272499',
                },
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                '&.Mui-disabled': {
                  backgroundColor: '#c0c0c0',
                  color: '#fff',
                }
              }}
              disabled={loading}
            >
              {loading ? <BeatLoader color="#fff" size={10} /> : 'Sign Up'}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
