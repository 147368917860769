import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './css/ReviewExam.css';
import BeatLoader from 'react-spinners/BeatLoader';
import mixpanel from './mixpanel';

function ReviewExam() {
    const { exam_id } = useParams();
    const navigate = useNavigate();
    const [examData, setExamData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchExamReview = async () => {
            try {
                setLoading(true);
                const jwt = localStorage.getItem('jwt');
                const response = await fetch('https://api.getmina.co/api/v1/panel/review_exam', {
                    method: 'POST',
                    headers: { 'Authorization': `Bearer ${jwt}`, 'Content-Type': 'application/json' },
                    body: JSON.stringify({ exam_id: exam_id })
                });
                const data = await response.json();
                console.log("Exam review data:", data);
                setExamData(data);
                mixpanel.track('Review Exam', {
                    date_taken: data.date_taken,
                    score: data.score
                });
            } catch (error) {
                console.error('Error fetching review:', error);
                setError('Failed to fetch exam review.');
            }
            setLoading(false);
        };

        fetchExamReview();
    }, [exam_id]);

    function formatDate(dateString) {
        const [year, month, day] = dateString.split('T')[0].split('-');
        return `${day}-${month}-${year}`;
    }

    const handleRedoExam = async () => {
        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch('https://api.getmina.co/api/v1/panel/redo-exam', {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${jwt}`, 'Content-Type': 'application/json' },
                body: JSON.stringify({ exam_id: exam_id })
            });
            const result = await response.json();
            if (!response.ok) throw new Error(result.message || 'Failed to redo the exam');
            alert('Exam has been reset. Good luck!');
            navigate(`/exam/${exam_id}`);
            mixpanel.track('Redo Exam', {
                exam_id: exam_id,
            });
        } catch (error) {
            console.error('Error redoing the exam:', error.message);
            alert('Failed to redo the exam.');
        }
    };

    if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;
    if (error) return <div>{error}</div>;
    if (!examData) return <div>No exam data available.</div>;

    return (
        <div className="page">
            <div className="review-exam">
                <h1>Exam Review: {exam_id}</h1>
                <div className="info-section">
                    <div className="info-box">
                        <h2>Date:</h2>
                        <p className="info-contentbox">{formatDate(examData.date_taken)}</p>
                    </div>
                    <div className="info-box">
                        <h2>Score:</h2>
                        <div className="info-contentbox">
                            <div className="metric">
                                <div className="metric-value circle">{examData.score}%</div>
                            </div>
                        </div>
                    </div>
                    <div className="info-box">
                        <h2>Average:</h2>
                        <div className="info-contentbox">
                            <div className="metric">
                                <div className="metric-value circle">
                                    {examData.questions.filter(q => q.is_correct).length} / {examData.questions.length}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="exam-responses">
                    {examData.questions.map((question, index) => (
                        <div key={index} className="response-card">
                            <div>
                                <h3 className="question-title">Question {index + 1}</h3>
                                <p className="selected-answer">Selected Answer: {question.selected_answer}</p>
                            </div>
                            <p className={question.is_correct ? "status correctchoice" : "status incorrectchoice"}>
                                {question.is_correct ? '✓' : '✗'}
                            </p>
                        </div>
                    ))}
                </div>
                <div className="button-container">
                    <button onClick={handleRedoExam} className="redo-exam-button">Redo Exam</button>
                    <button onClick={() => navigate(`/`)} className="redo-exam-button">Return to Dashboard</button>
                </div>
            </div>
            <div className="bottom-nav">
                <button onClick={handleRedoExam}>Redo Exam</button>
                <button onClick={() => navigate(`/`)}>Return to Dashboard</button>
            </div>
        </div>
    );
}

export default ReviewExam;