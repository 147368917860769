// ReferralCodeModal.js
import React, { useState } from 'react';
import './css/ReferralCodeModal.css';

const ReferralCodeModal = ({ onClose, onCheckout }) => {
    const [showInput, setShowInput] = useState(false);
    const [referralCode, setReferralCode] = useState('');

    const handleNoClick = () => {
        onClose();
        onCheckout();
    };

    const handleYesClick = () => {
        setShowInput(true);
    };

    const handleNextClick = () => {
        onClose();
        onCheckout(referralCode);
    };

    const handleOutsideClick = (e) => {
        if (e.target.className === 'referral-modal-overlay') {
            onClose();
        }
    };

    return (
        <div className="referral-modal-overlay" onClick={handleOutsideClick}>
            <div className="referral-modal">
                <div className="referral-modal-header">
                    <h2>Do you have a referral code?</h2>
                </div>
                <div className="referral-modal-body">
                    {showInput ? (
                        <>
                            <label htmlFor="referral-code" className="referral-code-label">Enter your referral code</label>
                            <input
                                type="text"
                                id="referral-code"
                                value={referralCode}
                                onChange={(e) => setReferralCode(e.target.value)}
                                className="referral-code-input"
                            />
                            <button onClick={handleNextClick} className="referral-button">Next</button>
                        </>
                    ) : (
                        <>
                            <button onClick={handleYesClick} className="referral-button">Yes</button>
                            <button onClick={handleNoClick} className="referral-button">No</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReferralCodeModal;
