import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './CreateStudyPlan.css';

const levels = ['N1', 'N2', 'N3', 'N4', 'N5'];
const topics = ['grammar', 'vocabulary', 'reading'];

function CreateStudyPlan() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1);

  const formatText = (text) => {
    if (!text) return '';
    const textWithLineBreaks = text.replace(/{{NEWLINE}}/g, '\n');
    return textWithLineBreaks.split(/(?<!\S)(\s{2,})(?!\S)(?!.*\))/g).map((part, index) => (
      <span key={index}>
        {part.trim().length === 0 ? <br /> : part.split('\n').map((line, i) => (
          <React.Fragment key={i}>
            {i > 0 && <br />}
            {line}
          </React.Fragment>
        ))}
      </span>
    ));
  };

  const fetchQuestions = async () => {
    if (!selectedLevel || !selectedTopic) {
      setError('Please select both level and topic.');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await fetch(`https://api.getmina.co/api/v1/panel/teacher/questions?level=${selectedLevel}&section=${selectedTopic}&user_id=${userId}`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${jwt}` }
      });
      const data = await response.json();
      console.log(data);
      setQuestions(data);
      setStep(2);
    } catch (error) {
      setError('Failed to fetch questions.');
    }
    setLoading(false);
  };

  const handleQuestionClick = (id) => {
    setSelectedQuestions((prevSelected) => 
      prevSelected.includes(id) 
        ? prevSelected.filter((questionId) => questionId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSubmit = async () => {
    if (selectedQuestions.length === 0) {
      setError('Please select at least one question.');
      return;
    }
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await fetch('https://api.getmina.co/api/v1/panel/create-personalized-exam', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwt}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ questions: selectedQuestions, user_id: userId, section: selectedTopic })
      });
      const result = await response.json();
      console.log("Exam created:", result);
      alert("Exam created successfully!");
      navigate('/DiagnosticExams');
    } catch (error) {
      console.error("Failed to create exam:", error);
      setError("Failed to create exam. Please try again.");
    }
  };

  const getQuestionColor = (question) => {
    if (question.previously_added) {
      if (question.answered_correctly === "correct") return 'green';
      if (question.answered_correctly === "false") return 'red';
      if (question.answered_correctly === "unsolved") return 'orange';
    }
    return '';
  };

  if (loading) return <div className="loading">Loading...</div>;

  return (
    <div className="container">
      <div className="content">
        <h1>Create Study Exam</h1>
        {error && <div className="error">{error}</div>}
        {step === 1 ? (
          <div className="step-1">
            <h2>Step 1: Select Level and Topic</h2>
            <div className="selectors">
              <select 
                value={selectedLevel} 
                onChange={(e) => setSelectedLevel(e.target.value)}
                required
              >
                <option value="">Select Level</option>
                {levels.map(level => (
                  <option key={level} value={level}>{level}</option>
                ))}
              </select>
              <select 
                value={selectedTopic} 
                onChange={(e) => setSelectedTopic(e.target.value)}
                required
              >
                <option value="">Select Topic</option>
                {topics.map(topic => (
                  <option key={topic} value={topic}>{topic}</option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="step-2">
            <h2>Step 2: Select Questions</h2>
            <p>Select questions for your exam:</p>
            <ul className="questions-list">
              {questions.map((question) => (
                <li
                  key={question.question_id}
                  className={`question-item ${selectedQuestions.includes(question.question_id) ? 'selected' : ''} ${getQuestionColor(question)}`}
                  onClick={() => handleQuestionClick(question.question_id)}
                >
                  <div className="question-text">{formatText(question.question_text)}</div>
                  {question.question_script && question.question_script !== "0" && (
                    <div className="question-script">{formatText(question.question_script)}</div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="sticky-footer">
        {step === 2 && (
          <button className="button secondary" onClick={() => setStep(1)}>Back</button>
        )}
        <button
          className="button active"
          onClick={step === 1 ? fetchQuestions : handleSubmit}
          disabled={step === 1 ? (!selectedLevel || !selectedTopic) : selectedQuestions.length === 0}
        >
          {step === 1 ? 'Fetch Questions' : 'Create Exam'}
        </button>
      </div>
    </div>
  );
}

export default CreateStudyPlan;