import React, { useState, useEffect, useRef, useCallback } from 'react';
import './css/AskMina.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import mixpanel from './mixpanel';
import arrow from './assets/icons/askminaarrow.png';
import cancel from './assets/icons/cancel.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import Mina from './assets/images/mina.png';

const ASK_MINA_HELP_URL = "https://api.getmina.co/api/v1/panel/get_helpopen";
const ASK_MINA_HELP_URL2 = "https://api.getmina.co/api/v1/panel/ask-mina-help";
const NORMAL_API_URL = "https://api.getmina.co/api/v1/panel/test-convo";

function AskMina({ questionId, initialChatHistory, questionnumber, hint, onClose }) {
    const isSmallScreen = useMediaQuery('(max-width: 600px)');
    const [input, setInput] = useState('');
    const [displayChatHistory, setDisplayChatHistory] = useState(() => {
        if (initialChatHistory && initialChatHistory.length > 0) {
            return [initialChatHistory[initialChatHistory.length - 1]]; // Start with only the last message
        }
        return [];
    });
    const [firstRequestDone, setFirstRequestDone] = useState(!questionId);
    const [responseHistory, setResponseHistory] = useState([]); // Store extracted "history" parts
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const inactivityTimeoutRef = useRef(null);
    const [inactivityMessageSent, setInactivityMessageSent] = useState(false); // Track if inactivity message is sent

    const clearInactivityTimeout = useCallback(() => {
        if (inactivityTimeoutRef.current) {
            clearTimeout(inactivityTimeoutRef.current);
            inactivityTimeoutRef.current = null;
        }
    }, []);

    const sendSystemMessage = useCallback(() => {
        if (!inactivityMessageSent) { // Only send message if it hasn't been sent
            const message = "おはよう、ミナです 🌸 I am here if you need help solving this question! Ask me anything 😊";

            const systemMessage = { role: 'system', content: message };
            setDisplayChatHistory(prev => [...prev, systemMessage]);
            setInactivityMessageSent(true); // Mark message as sent
        }
    }, [inactivityMessageSent]);

    const setInactivityTimeout = useCallback(() => {
        clearInactivityTimeout(); // Clear any existing timeout
        inactivityTimeoutRef.current = setTimeout(sendSystemMessage, 10000); // Set new timeout for 10 seconds
    }, [clearInactivityTimeout, sendSystemMessage]);

    const sendInput = async () => {
        if (!input.trim() || isLoading) return; // Prevent sending if input is empty or already loading

        clearInactivityTimeout(); // Clear inactivity timeout when user sends input

        setIsLoading(true); // Set loading state

        const userInput = input;
        setInput(''); // Clear input field after sending

        // Add user input to display chat history
        const newUserMessage = { role: 'user', content: userInput };
        setDisplayChatHistory(prev => [...prev, newUserMessage]);

        try {
            const requestBody = {
                user_input: userInput,
                ...(questionId && !firstRequestDone ? { question_id: questionId, help_type: "explanation" } : {}),
                history: responseHistory, // Send the history from previous responses
            };

            if (questionId) {
                mixpanel.track('Question Ask Mina', {
                    question_id: questionId,
                    question_number: questionnumber,
                    user_input: userInput,
                });
            } else {
                mixpanel.track('Ask Mina', {
                    user_input: userInput,
                });
            }

            const url = responseHistory.length === 0 ? 
                questionId ? ASK_MINA_HELP_URL : ASK_MINA_HELP_URL2 : NORMAL_API_URL;

            const jwt = localStorage.getItem('jwt');
            const response = await fetch(url, {
                method: "POST",
                headers: { 'Authorization': `Bearer ${jwt}`, "Content-Type": "application/json" },
                body: JSON.stringify(requestBody)
            });

            const reader = response.body.getReader();
            let currentDisplayContent = '';
            let fullResponse = '';

            function processText({ done, value }) {
                if (value) {
                    const chunk = new TextDecoder("utf-8").decode(value, { stream: !done });
                    fullResponse += chunk; // Accumulate the full response

                    const jsonStartIndex = chunk.indexOf('{"history":');
                    if (jsonStartIndex !== -1) {
                        currentDisplayContent += chunk.substring(0, jsonStartIndex);
                    } else {
                        currentDisplayContent += chunk;
                    }

                    setDisplayChatHistory(prev => {
                        const newHistory = [...prev];
                        if (newHistory.length > 0 && newHistory[newHistory.length - 1].role === 'system') {
                            newHistory[newHistory.length - 1].content = currentDisplayContent;
                        } else {
                            newHistory.push({ role: 'system', content: currentDisplayContent });
                        }
                        return newHistory;
                    });
                }

                if (!done) {
                    return reader.read().then(processText);
                } else {
                    const historyMatch = fullResponse.match(/{"history":\s*\[.*\]}/);
                    if (historyMatch) {
                        const history = JSON.parse(historyMatch[0]).history;
                        setResponseHistory(history); // Update response history
                    }

                    const historyIndex = currentDisplayContent.indexOf('{"history":');
                    if (historyIndex !== -1) {
                        currentDisplayContent = currentDisplayContent.substring(0, historyIndex);
                    }

                    setDisplayChatHistory(prev => {
                        const newHistory = [...prev];
                        if (newHistory.length > 0 && newHistory[newHistory.length - 1].role === 'system') {
                            newHistory[newHistory.length - 1].content = currentDisplayContent;
                        } else {
                            newHistory.push({ role: 'system', content: currentDisplayContent });
                        }
                        return newHistory;
                    });

                    setIsLoading(false); // Reset loading state
                    if (questionId && !firstRequestDone) setFirstRequestDone(true);

                    setInactivityTimeout(); // Reset inactivity timeout after receiving response
                }
            }

            reader.read().then(processText);
        } catch (error) {
            console.error("Error fetching Mina's response:", error);
            setIsLoading(false); // Reset loading state on error
        }
    };

    const handleBack = () => {
       onClose(displayChatHistory); // Pass the updated chatHistory back to the parent component
    };

    const handleClose = () => {
        onClose(displayChatHistory);
    };

    useEffect(() => {
        setInactivityTimeout(); // Set inactivity timeout on component mount

        return () => {
            clearInactivityTimeout(); // Clear inactivity timeout on component unmount
        };
    }, [setInactivityTimeout, clearInactivityTimeout]);

    useEffect(() => {
        if (input || displayChatHistory.some(message => message.role === 'user')) {
            clearInactivityTimeout(); // Clear the timeout if there is any user input
            setInactivityMessageSent(false); // Reset inactivity message sent status on user input
        } else {
            setInactivityTimeout(); // Reset inactivity timeout on input change or new messages
        }
    }, [input, displayChatHistory, setInactivityTimeout, clearInactivityTimeout]);

    return (
        <div className={`ask-mina-modal ${!questionId && !isSmallScreen ? 'ask-mina-right' : ''}`}>
            <div className="askmina askmina-fullscreen">
                <div className="upper-left">
                    {!isSmallScreen ? (
                        !questionId && <div onClick={handleClose} aria-label="close" className="icon-container">
                            <img src={cancel} alt="close" className="close-icon" />
                        </div>
                    ) : (
                        <div onClick={handleBack} aria-label="back" className="back-button upper-left">
                            <ArrowBackIosNewIcon />
                            Back
                        </div>
                    )}
                </div>
                {displayChatHistory.length === 0 && (
                    <div className="welcome-container">
                        <img src={Mina} alt="Mina Logo" className="mina" />
                        <div className="welcome-message">Let’s solve this question together!</div>
                        <div className="suggestions-container"></div>
                    </div>
                )}

                <div className="chat-container">
                    {displayChatHistory.map((item, index) => (
                        <div key={index} className={`chat-message ${item.role}`}>
                            {item.content}
                        </div>
                    ))}
                </div>
                <div className='chat-container-border'>
                    <div className="input-container">
                        <input
                            type="text"
                            value={input}
                            onChange={e => setInput(e.target.value)}
                            placeholder="Message MINA..."
                            onKeyPress={e => e.key === 'Enter' && sendInput()}
                        />
                        <button
                            className="arrow-button"
                            disabled={isLoading}
                            onClick={sendInput}
                            style={isLoading ? { backgroundColor: 'grey' } : {}}
                        >
                            <img src={arrow} className="arrow-image" alt="arrow" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AskMina;