import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import './dashboardstyle.css';

function UsersListPage() {
  const { examId } = useParams();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const jwt = localStorage.getItem('jwt');
        const response = await fetch(`https://api.getmina.co/api/v1/panel/diagnostic/admin/${examId}/users`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${jwt}` }
        });
        const data = await response.json();
        console.log(data);
        setUsers(data);
        console.log(data);
      } catch (error) {
        setError('Failed to fetch users.');
      }
      setLoading(false);
    };

    fetchUsers();
  }, [examId]);

  if (loading) return <div className="loader-container">Loading...</div>;
  if (error) return <div className="error-container">{error}</div>;

  return (
    <div className="container">
      <h1 className="page-title">Users for Exam {examId}</h1>
      {users.length > 0 ? (
        <ul className="users-list">
          {users.map((user) => (
            <li key={user.user_id} className="user-item">
              <Link to={`/user/${user.user_id}/exams`} className="user-link">
               
                <span className="username">{user.email}</span>
<br></br>
                <span className="user-id">ID: {user.user_id}</span>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <p className="no-users">No users found for this exam.</p>
      )}
    </div>
  );
}

export default UsersListPage;