import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './css/ReviewExam.css';
import BeatLoader from 'react-spinners/BeatLoader';
import mixpanel from './mixpanel'; // Import Mixpanel

function ReviewMockExam() {
    const { exam_id } = useParams();
    const navigate = useNavigate();
    const [examData, setExamData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchExamReview = async () => {
            try {
                setLoading(true);
                
                const jwt = localStorage.getItem('jwt');
                const response = await fetch('https://api.getmina.co/api/v1/panel/review_exam', {
                    method: 'POST',
                    headers: {     'Authorization': `Bearer ${jwt}`,'Content-Type': 'application/json' },
                    body: JSON.stringify({ exam_id: exam_id})
                });
                const data = await response.json();
                setExamData(data);
               
            } catch (error) {
                console.error('Error fetching review:', error);
                setError('Failed to fetch exam review.');
            }
            setLoading(false);
        };

        fetchExamReview();
    }, [exam_id]);

    function formatDate(dateString) {
        const [year, month, day] = dateString.split('T')[0].split('-');
        return `${day}-${month}-${year}`;  // Reformat to day-month-year
    }

    const handleRedoExam = async () => {
        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch('https://api.getmina.co/api/v1/panel/redo-exam', {
                method: 'POST',
                headers: {   'Authorization': `Bearer ${jwt}`, 'Content-Type': 'application/json' },
                body: JSON.stringify({ exam_id: exam_id })
            });
            const result = await response.json();
            if (!response.ok) throw new Error(result.message || 'Failed to redo the exam');
            alert('Exam has been reset. Good luck!');
            navigate(`/mockexam/${exam_id}`);  // Adjust to your exam route
            mixpanel.track('Redo Exam', {
                exam_id: exam_id,
            });
        } catch (error) {
            console.error('Error redoing the exam:', error.message);
            alert('Failed to redo the exam.');
        }
    };

    if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;
    if (error) return <div>{error}</div>;
    if (!examData) return <div>No exam data available.</div>;

    return (
        <div className="page">
        <div className="review-exam">
            <h1 style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'left' }}>Exam Review: {exam_id}</h1>
            <div className="info-section">
                <div className="info-box">
                    <h2>Date of the Exam:</h2>
                    <p className="info-contentbox1">{formatDate(examData.date_taken)}</p>
                </div>
                
                <div className="info-box">
                    <h2>Learning History:</h2>
                    <div className="info-contentbox2">
                        <div className="metric">
                            <span className="metric-title">Correct Answers</span>
                            <div className="metric-value circle">{examData.responses.filter(r => r.is_correct).length} / {examData.responses.length}</div>
                        </div>
                        <div className="metric">
                            <span className="metric-title">Average</span>
                            <div className="metric-value circle">{examData.score}%</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="exam-responses">
                {examData.responses.map((response, index) => (
                    <div key={index} className="response-card">
                        <h3 className="question-title">Question {index + 1}</h3>
                        <p className="selected-answer">Selected Answer: {response.selected_answer}</p>
                        <p className={response.is_correct ? "status correctchoice" : "status incorrectchoice"}>
                            {response.is_correct ? '✓' : '✗'}
                        </p>
                    </div>
                ))}
            </div>
            <div className="button-container">
    <button onClick={handleRedoExam} className="redo-exam-button">Redo Exam</button>
    <button onClick={() => navigate(`/`)} className="redo-exam-button">Return to Dashboard</button>
</div>
        </div>
        </div>
    );
}

export default ReviewMockExam;
