import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './css/ReviewExam.css';
import BeatLoader from 'react-spinners/BeatLoader';
import mixpanel from './mixpanel';

function ReviewPersonalizedExam() {
    const { exam_id } = useParams();
    const navigate = useNavigate();
    const [examData, setExamData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchExamReview = async () => {
            try {
                setLoading(true);
                
                const jwt = localStorage.getItem('jwt');
                const response = await fetch(`https://api.getmina.co/api/v1/panel/personalized-exam/teacher/${exam_id}/responses`, {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${jwt}`, 'Content-Type': 'application/json' },
                });
                const data = await response.json();
                console.log('API Response:', data);
                setExamData(data);
                mixpanel.track('Review Exam', {
                    exam_id: exam_id,
                    score: calculateScore(data)
                });
            } catch (error) {
                console.error('Error fetching review:', error);
                setError('Failed to fetch exam review.');
            }
            setLoading(false);
        };

        fetchExamReview();
    }, [exam_id]);

    function calculateScore(responses) {
        if (!responses || responses.length === 0) return 0;
        const correctAnswers = responses.filter(r => r.is_correct).length;
        return Math.round((correctAnswers / responses.length) * 100);
    }

    function formatDate(dateString) {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB'); // Format as DD/MM/YYYY
    }


    if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;
    if (error) return <div>{error}</div>;
    if (!examData || examData.length === 0) return <div>No exam data available.</div>;

    const score = calculateScore(examData);

    return (
        <div className="page">
            <div className="review-exam">
                <h1>Exam Review: {exam_id}</h1>
                <div className="info-section">
                    <div className="info-box">
                        <h2>Date:</h2>
                        <p className="info-contentbox">{formatDate(examData[0].response_time)}</p>
                    </div>
                    <div className="info-box">
                        <h2>Score:</h2>
                        <div className="info-contentbox">
                            <div className="metric">
                                <div className="metric-value circle">{score}%</div>
                            </div>
                        </div>
                    </div>
                    <div className="info-box">
                        <h2>Average:</h2>
                        <div className="info-contentbox">
                            <div className="metric">
                                <div className="metric-value circle">
                                    {`${examData.filter(r => r.is_correct).length} / ${examData.length}`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="exam-responses">
                    {examData.map((response, index) => (
                        <div key={index} className="response-card">
                            <div>
                                <h3 className="question-title">Question {index + 1}</h3>
                                <p className="question-text">{response.question_text}</p>
                                <p className="selected-answer">Selected Answer: {response.selected_answer}</p>
                                <p className="correct-answer">Correct Answer: {response.correct_answer}</p>
                            </div>
                            <p className={response.is_correct ? "status correctchoice" : "status incorrectchoice"}>
                                {response.is_correct ? '✓' : '✗'}
                            </p>
                        </div>
                    ))}
                </div>
                <div className="button-container">
                  
                    <button onClick={() => navigate(`/`)} className="redo-exam-button">Return to Dashboard</button>
                </div>
            </div>
            <div className="bottom-nav">

                <button onClick={() => navigate(`/`)}>Return to Dashboard</button>
            </div>
        </div>
    );
}

export default ReviewPersonalizedExam;