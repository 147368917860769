import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import './css/StudyPlan.css';
import Dropdown from './Dropdown';
import BeatLoader from 'react-spinners/BeatLoader';
import Logo from './assets/images/logo.png';
import UserDropdown from './UserDropdown';
import mixpanel from './mixpanel';
import ReferralCodeModal from './ReferralCodeModal';
import LockedExamModal from './LockedExamModal';
import { FaBars } from 'react-icons/fa';

const GET_PERSONALIZED_EXAMS_API_URL = "https://api.getmina.co/api/v1/panel/get-personalized-exams-user";
const DIAGNOSTIC_EXAMS_API_URL = "https://api.getmina.co/api/v1/panel/diagnostic-exams";

// khabbion bel .env ya dandoun 
// --joe

function StudyPlan() {
    const navigate = useNavigate();
    const levels = ["N1", "N2", "N3", "N4", "N5"];

    const [selectedLevel, setSelectedLevel] = useState(() => localStorage.getItem('selectedLevel') || "N3");
    const [sectionData, setSectionData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userStatus, setUserStatus] = useState(null);
    const [userData, setUserData] = useState(null);
    const [showReferralModal, setShowReferralModal] = useState(false);
    const [showLockedExamModal, setShowLockedExamModal] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [diagnosticExam, setDiagnosticExam] = useState(null);

    const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
    const handleLockedExamClick = () => setShowLockedExamModal(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const jwt = localStorage.getItem('jwt');

                // Fetch diagnostic exam status
                const diagnosticResponse = await fetch(`${DIAGNOSTIC_EXAMS_API_URL}/${selectedLevel}`, {
                    headers: { 'Authorization': `Bearer ${jwt}` }
                });
                const diagnosticExams = await diagnosticResponse.json();

                let incompleteExam = null;
                if (Array.isArray(diagnosticExams) && diagnosticExams.length > 0) {
                    incompleteExam = diagnosticExams.find(exam => !exam.completed);
                }
                setDiagnosticExam(incompleteExam);

                // Fetch personalized exams if diagnostic exam is completed
                if (!incompleteExam) {
                    const personalizedResponse = await fetch(GET_PERSONALIZED_EXAMS_API_URL, {
                        method: "POST",
                        headers: {
                            'Authorization': `Bearer ${jwt}`,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({ level: selectedLevel })
                    });
                    const personalizedData = await personalizedResponse.json();
                    setSectionData(Array.isArray(personalizedData) ? personalizedData : []);
                } else {
                    setSectionData([]);
                }

                // Fetch user data
                const meResponse = await fetch("https://api.getmina.co/api/v1/auth/me", {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${jwt}`,
                        "Content-Type": "application/json"
                    },
                });
                const meData = await meResponse.json();

                if (meData.msg === "Token has expired") {
                    localStorage.removeItem('jwt');
                    window.location.reload();
                } else {
                    setUserStatus(meData.status);
                    setUserData(meData);
                    mixpanel.identify(meData.user_id);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("Error fetching data");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [selectedLevel]);

    useEffect(() => {
        localStorage.setItem('selectedLevel', selectedLevel);
    }, [selectedLevel]);

    const renderExamItem = (exam, index) => (
        <div key={index} onClick={() => exam.isLocked ? handleLockedExamClick() : null}>
            <Link
                to={exam.completed ? `/review-personalized/${exam.exam_id}` : (exam.isLocked ? "#" : `/personalized-exam/${exam.exam_id}`)}
                className={`exam-item-box ${exam.completed ? 'exam-item-completed' : 'exam-item-not-completed'} ${exam.isLocked ? 'exam-item-locked' : ''}`}
                onClick={() => {
                    if (exam.completed) {
                        mixpanel.track('Review Exam', {
                            exam_id: exam.exam_id,
                            exam_level: selectedLevel,
                        });
                    } else if (!exam.completed && !exam.isLocked) {
                        mixpanel.track('New Exam Session', {
                            exam_id: exam.exam_id,
                            exam_level: selectedLevel,
                        });
                    }
                }}
            >
                <div className="exam-item-info">
                    <div className="exam-title-text">Exam {exam.exam_id}</div>
                    <div className="exam-subtitle-text">
                        {exam.categories.join(", ")}
                    </div>
                </div>
            </Link>
        </div>
    );

    return (
        <div className="study-plan-container">
            <div className="topbar-container">
                <div className="topbar-container-left">
                    <img src={Logo} alt="InnovateTech Logo" className="logo" />
                </div>
                <div className="topbar-right">
                    <div className="desktop-menu">
                        <NavLink to="/" className="topbar-link">Dashboard</NavLink>
                        <NavLink to="/StudyPlan" className="topbar-link">Study Plan</NavLink>
                        {userStatus !== "active" && (
                            <button onClick={() => setShowReferralModal(true)} className="upgrade-button">
                                Get full access
                            </button>
                        )}
                    </div>
                    <FaBars className="menu-icon" onClick={toggleMobileMenu} />
                    {isMobileMenuOpen && (
                        <div className="mobile-menu">
                            <NavLink to="/" className="mobile-menu-item" onClick={toggleMobileMenu}>Dashboard</NavLink>
                            <NavLink to="/StudyPlan" className="mobile-menu-item" onClick={toggleMobileMenu}>Study Plan</NavLink>
                            {userStatus !== "active" && (
                                <button onClick={() => setShowReferralModal(true)} className="mobile-menu-item">
                                    Get full access
                                </button>
                            )}
                        </div>
                    )}
                    {userData && <UserDropdown userData={userData} />}
                </div>
            </div>
            <h1>Study Plan</h1>
            {diagnosticExam && (
                <div className="study-plan-level-selector">
                    <div className="study-plan-selector-label">Level</div>
                    <Dropdown
                        options={levels}
                        selected={selectedLevel}
                        onChange={setSelectedLevel}
                        className="study-plan-dropdown"
                    />
                </div>
            )}

            {loading ? (
                <div className="loader-container"><BeatLoader color="#3E00EF" /></div>
            ) : error ? (
                <div>{error}</div>
            ) : (
                <div className="exams-list-container">
                    {diagnosticExam ? (
                        <div
                            onClick={() => navigate(`/diagnostic-exam/${diagnosticExam.exam_id}`)}
                            className="exam-item-box exam-item-not-completed"
                        >
                            <div className="exam-item-info">
                                <div className="exam-title-text">Diagnostic Exam</div>
                                <div className="exam-subtitle-text">Complete this exam to unlock personalized study plan</div>
                            </div>
                        </div>
                    ) : (
                        sectionData.map(renderExamItem)
                    )}
                </div>
            )}
            {showReferralModal && (
                <ReferralCodeModal onClose={() => setShowReferralModal(false)} />
            )}
            {showLockedExamModal && (
                <LockedExamModal
                    onClose={() => setShowLockedExamModal(false)}
                    onGetFullAccess={() => setShowLockedExamModal(false)}
                />
            )}
        </div>
    );
}

export default StudyPlan;