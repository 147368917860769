import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BeatLoader from 'react-spinners/BeatLoader';
import Mina from './assets/images/mina.png';
import './css/Login.css';
import mixpanel from './mixpanel';  // Import the initialized Mixpanel instance

const theme = createTheme();

function EmailVerification() {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleVerify = async (event) => {
    event.preventDefault();
    const jwt = localStorage.getItem('jwt'); // Get JWT from localStorage

    if (!otp) {
      setErrorMessage('OTP code is required.');
      return;
    }

    setLoading(true);
    setErrorMessage('');

    try {
      const response = await fetch('https://api.getmina.co/api/v1/auth/verify-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify({ otp })
      });
      const verificationData = await response.json();
      setLoading(false);

      if (response.ok) {
        console.log('Email verified successfully:', verificationData);

        const meResponse = await fetch("https://api.getmina.co/api/v1/auth/me", {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${jwt}`,
            "Content-Type": "application/json"
          },
        });
        const meData = await meResponse.json();
        console.log("RESP", meData);

        // Ensure JWT is stored and user is identified
        console.log("THE JWT", jwt);
        localStorage.setItem('jwt', jwt);

        mixpanel.identify(meData.user_id);
        mixpanel.people.set({
          $name: `${meData.FirstName} ${meData.LastName}`, // Assuming these fields are returned
          $email: meData.email,  // Mixpanel's special property for email
          status: meData.status,
        });

        mixpanel.track('Register', {
          email: meData.email,
        });
      


        window.location.href = '/';
      } else {
        setErrorMessage(verificationData.message || 'Verification failed');
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('Error during email verification: ' + error.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={Mina} alt="Mina Logo" className="mina" />
          <Typography component="h1" variant="h5">
            Verify Email
          </Typography>
          {errorMessage && (
            <Alert severity="error" sx={{ width: '100%', marginTop: 2 }}>
              {"Email verification failed"}
            </Alert>
          )}
          <Box component="form" noValidate onSubmit={handleVerify} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="otp"
              label="OTP Code"
              name="otp"
              autoComplete="otp"
              autoFocus
              value={otp}
              onChange={e => setOtp(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#3E00EF',
                color: 'white',
                borderRadius: '5px',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#272499',
                },
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                '&.Mui-disabled': {
                  backgroundColor: '#c0c0c0',
                  color: '#fff',
                }
              }}
              disabled={loading}
            >
              {loading ? <BeatLoader color="#fff" size={10} /> : 'Verify Email'}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default EmailVerification;
