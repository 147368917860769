import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './dashboardstyle.css';

function UserExamsPage() {
  const { userId } = useParams();
  const [personalizedExams, setPersonalizedExams] = useState([]);
  const [diagnosticExams, setDiagnosticExams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleDiagnosticExamClick = (examId) => {
    navigate(`/diagnostic-exam-results/${userId}/${examId}`);
  };

  const handlePersonalizedExamClick = (examId) => {
    navigate(`/review-personalized/${examId}`);
  };

  const handleCreateStudyPlan = () => {
    navigate(`/create-study-plan/${userId}`);
  };

  useEffect(() => {
    const fetchExams = async () => {
      try {
        const jwt = localStorage.getItem('jwt');
        
        // Fetch personalized exams
        const personalizedResponse = await fetch(`https://api.getmina.co/api/v1/panel/get-personalized-exams-admin`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
          },
          body: JSON.stringify({ user_id: userId })
        });
        const personalizedData = await personalizedResponse.json();
        console.log(personalizedData);
        setPersonalizedExams(personalizedData); // Remove the filter to show all exams

        // Fetch diagnostic exams
        const diagnosticResponse = await fetch(`https://api.getmina.co/api/v1/panel/diagnostic/admin/${userId}/exams`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${jwt}` }
        });
        const diagnosticData = await diagnosticResponse.json();
        setDiagnosticExams(diagnosticData.filter(exam => exam.completed));

      } catch (error) {
        console.error(error);
        setError('Failed to fetch exams.');
      }
      setLoading(false);
    };

    fetchExams();
  }, [userId]);

  if (loading) return <div className="loader-container">Loading...</div>;
  if (error) return <div className="error-container">{error}</div>;

  return (
    <div className="container">
      <h1 className="page-title">Exams for User {userId}</h1>
      
      <div className="exam-section">
        <h2>Personalized Exams</h2>
        {personalizedExams.length > 0 ? (
          <ul className="exam-list">
            {personalizedExams.map((exam) => (
              <li key={exam.exam_id} className={`exam-item ${exam.completed ? 'completed' : 'not-completed'}`}>
               <div 
                  className="exam-link" 
                  onClick={() => exam.completed ? handlePersonalizedExamClick(exam.exam_id):null}
                >
                  <span className="exam-id">Exam ID: {exam.exam_id}</span>
                  <span className="exam-date">Created on {new Date(exam.created_at).toLocaleDateString()}</span>
                  {exam.completed ? (
                    <>
                      <span className="exam-status">Completed</span>
                      {exam.score !== null && <span className="exam-score">Score: {exam.score.toFixed(2)}%</span>}
                    </>
                  ) : (
                    <span className="exam-status">Not Completed</span>
                  )}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="no-exams">No personalized exams found.</p>
        )}
      </div>

      <div className="exam-section">
        <h2>Diagnostic Exams</h2>
        {diagnosticExams.length > 0 ? (
          <ul className="exam-list">
            {diagnosticExams.map((exam) => (
              <li key={exam.diagnostic_exam_id} className="exam-item completed">
                 <div 
                  className="exam-link" 
                  onClick={() => handleDiagnosticExamClick(exam.diagnostic_exam_id)}
                >
                  <span className="exam-id">Level: {exam.level}</span>
                  <span className="exam-date">Completed on {new Date(exam.date_taken).toLocaleDateString()}</span>
                  {exam.score !== null && <span className="exam-score">Score: {exam.score.toFixed(2)}%</span>}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="no-exams">No completed diagnostic exams found.</p>
        )}
      </div>

      <div className="sticky-footer">
        <button className="create-study-plan-btn" onClick={handleCreateStudyPlan}>
          Create New Study Plan
        </button>
      </div>
    </div>
  );
}

export default UserExamsPage;