import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './dashboardstyle.css';

function DiagnosticExamResultsPage() {
  const { userId, examId } = useParams();
  const [examData, setExamData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExamResults = async () => {
      try {
        const jwt = localStorage.getItem('jwt');
        const response = await fetch(`https://api.getmina.co/api/v1/panel/diagnostic/admin/${userId}/exams/${examId}/responses`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${jwt}` }
        });
        const data = await response.json();
        console.log(data);
        setExamData(data);
      } catch (error) {
        console.error(error);
        setError('Failed to fetch exam results.');
      }
      setLoading(false);
    };

    fetchExamResults();
  }, [userId, examId]);

  if (loading) return <div className="loader-container">Loading...</div>;
  if (error) return <div className="error-container">{error}</div>;
  if (!examData) return <div className="error-container">No exam data available.</div>;

  return (
    <div className="container">
      <h1 className="page-title">Diagnostic Exam Results</h1>
      <h2>Level: {examData.level}</h2>
      <div className="questions-container">
        {examData.responses.map((question, index) => (
          <div key={question.question_id} className={`question-item ${question.correct_answer === question.selected_answer ? 'correct' : 'incorrect'}`}>
            <h3>Question {index + 1}</h3>
            <p className="question-text">{question.question_text}</p>
            <p>Selected Answer: {question.selected_answer}</p>
            <p>Correct Answer: {question.correct_answer}</p>
            <div className="choices">
              {question.choices.map((choice, choiceIndex) => (
                <div 
                  key={choiceIndex} 
                  className={`choice ${choice === question.selected_answer ? 'selected' : ''} ${choice === question.correct_answer ? 'correct' : ''}`}
                >
                  {choice}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DiagnosticExamResultsPage;