import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './css/Exam.css';
import BeatLoader from 'react-spinners/BeatLoader';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import mixpanel from './mixpanel';
import useMediaQuery from '@mui/material/useMediaQuery';

const DIAGNOSTIC_QUESTIONS_API_URL = "https://api.getmina.co/api/v1/panel/diagnostic/get-questions";

function DiagnosticExam() {
    const SUBMIT_API_URL = "https://api.getmina.co/api/v1/panel/diagnostic/submit"
  const navigate = useNavigate();
  const { exam_id } = useParams();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [responses, setResponses] = useState([]);
  const [content, setContent] = useState([]);
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [userSelectedAnswer, setUserSelectedAnswer] = useState(null);
  const [revealAnswer, setRevealAnswer] = useState(false);
  const [isAnswerRevealed, setIsAnswerRevealed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const examIdNumber = Number(exam_id);
  const hasFetchedQuestions = useRef(false);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setLoading(true);
        const jwt = localStorage.getItem('jwt');
        const response = await fetch(`${DIAGNOSTIC_QUESTIONS_API_URL}/${examIdNumber}`, {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${jwt}`
          },
        });
        const data = await response.json();
        console.log(data);
        setContent(data.map(question => ({ ...question, selectedAnswer: null, isAnswered: false })));
        setLoading(false);
      } catch (error) {
        setError("Error fetching questions");
        setLoading(false);
      }
    };

    fetchQuestions();
    hasFetchedQuestions.current = true;
  }, [examIdNumber]);



  const handleSubmit = async () => {
    if (currentContentIndex === content.length - 1) {
      const requestBody = { diagnostic_exam_id: exam_id, responses: responses };
      try {
        const jwt = localStorage.getItem('jwt');
        const response = await fetch(SUBMIT_API_URL, {
          method: "POST",
          headers: { 'Authorization': `Bearer ${jwt}`, "Content-Type": "application/json" },
          body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to submit exam: ${response.status} ${errorText}`);
        }

        alert("Exam submitted successfully!");
        mixpanel.track('Exam Completed', {
          exam_id: exam_id,
         exam_type: "diagnostic_exam"
        });

        navigate(`/StudyPlan`);
      } catch (error) {
        console.error("Failed to submit exam:", error.message);
      }
    } else {
      setCurrentContentIndex(currentContentIndex + 1);
      setIsAnswerRevealed(false);
    }
  };

  const handleAnswerSelection = (index) => {
    if (!isAnswerRevealed) {
      setUserSelectedAnswer(index);
      setRevealAnswer(false);

      const updatedContent = content.map((question, idx) =>
        idx === currentContentIndex ? { ...question, selectedAnswer: index } : question
      );
      setContent(updatedContent);
      const questionId = content[currentContentIndex]?.question_id;
      const selectedAnswer = content[currentContentIndex]?.choices.choices[index];

      const newResponses = responses.filter(response => response.question_id !== questionId);
      newResponses.push({ question_id: questionId, selected_answer: selectedAnswer });
      setResponses(newResponses);
    }
  };

  const handleRevealAnswer = () => {
 

    if (isAnswerRevealed) {
      const nextContentIndex = currentContentIndex + 1;
      if (nextContentIndex < content.length) {
        setCurrentContentIndex(nextContentIndex);
        setUserSelectedAnswer(content[nextContentIndex]?.selectedAnswer);
        setRevealAnswer(content[nextContentIndex]?.isAnswered);
        setIsAnswerRevealed(content[nextContentIndex]?.isAnswered);
      }
      if (isAnswerRevealed && currentContentIndex === content.length - 1) {
        handleSubmit();
      }
    } else {
      setUserSelectedAnswer(content[currentContentIndex]?.selectedAnswer);
      setRevealAnswer(true);
      setIsAnswerRevealed(true);

      const updatedContent = content.map((question, idx) =>
        idx === currentContentIndex ? { ...question, isAnswered: true } : question
      );
      setContent(updatedContent);
      mixpanel.track('Reveal Answer', { question_id: content[currentContentIndex]?.question_id, question_number: currentContentIndex + 1 });
    }
  };

  const handlePreviousQuestion = () => {
    if (currentContentIndex > 0) {
      const previousContentIndex = currentContentIndex - 1;
      setCurrentContentIndex(previousContentIndex);
      setUserSelectedAnswer(content[previousContentIndex]?.selectedAnswer);
      setRevealAnswer(true);
      setIsAnswerRevealed(true);
    }
  };

  const handleBackButtonClick = () => {
    mixpanel.track('Cancel Exam', { exam_id: exam_id, question_id: content[currentContentIndex]?.question_id, question_number: currentContentIndex + 1 });
    navigate('/');
  };

  const formatText = (text) => {
    if (!text || text === '0') return '';
    return text.split(/(?<!\S)(\s{2,})(?!\S)(?!.*\))/g).map((part, index) => (
      <span key={index}>{part.trim().length === 0 ? <br /> : part}</span>
    ));
  };

  if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;

  if (error) {
    return <div>{error}</div>;
  }

  if (content.length === 0 || !content[currentContentIndex]) {
    return <div>No content available</div>;
  }

  const questionNumber = currentContentIndex + 1;
  const { script, question, instruction, choices, answer, explanation, selectedAnswer, isAnswered } = content[currentContentIndex];

  return (
    <div className="Exam">
      <>
        <div className={`upper-bar `}>
          <div onClick={handleBackButtonClick} aria-label="back" className="back-button upper-left">
            <ArrowBackIosNewIcon />
            Back
          </div>
          <div className="upper-center">{ `Diagnostic Exam` }</div>
          <div className="upper-right">Question {questionNumber}</div>
        </div>
        <div className="content"style={{ paddingLeft: '50px', paddingRight: '50px' }} >
          {isSmallScreen ? (
            <>
              <div className="right-section">
                {instruction && <p>{formatText(instruction)}</p>}
                {script && (
                  <div className="passage-box">
                    {formatText(script)}
                  </div>
                )}
                <div className="question-answer">
                  <div className="question-text">{formatText(question)}</div>
                  <div className="answer-section">
                    {choices.choices.map((choice, index) => (
                      <div
                        key={index}
                        className={`answer-option ${selectedAnswer === index ? 'selected' : ''} ${revealAnswer ? (choice === answer ? 'correct' : (selectedAnswer === index ? 'incorrect' : '')) : ''}`}
                        onClick={() => !isAnswered && handleAnswerSelection(index)}
                      >
                        {choice}
                      </div>
                    ))}
                  </div>
                
                  {isAnswerRevealed && explanation && (
                    <div className="explanation-box">
                      <strong>Explanation:</strong> {explanation}
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mock-section">
                {instruction && <p>{formatText(instruction)}</p>}
                {script && (
                  <div className="passage-box">
                    {formatText(script)}
                  </div>
                )}
                <div className="question-answer">
                  <div className="question-title"></div>
                  <div className="question-text">{formatText(question)}</div>
                  <div className="answer-section">
                    {choices.choices.map((choice, index) => (
                      <div
                        key={index}
                        className={`answer-option ${selectedAnswer === index ? 'selected' : ''} ${revealAnswer ? (choice === answer ? 'correct' : (selectedAnswer === index ? 'incorrect' : '')) : ''}`}
                        onClick={() => !isAnswered && handleAnswerSelection(index)}
                      >
                        {choice}
                      </div>
                    ))}
                  </div>
               
                  {isAnswerRevealed && explanation && (
                    <div className="explanation-box">
                      <strong>Explanation:</strong> {explanation}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className={`lower-bar `}>
          <div className="lower-left"></div>
          <div className="lower-right">
            <button className="previous-button" onClick={handlePreviousQuestion}>Previous</button>
            <button className="reveal-answer-button" onClick={handleRevealAnswer} disabled={userSelectedAnswer === null}>
              {isAnswerRevealed ? currentContentIndex === content.length - 1 ? "Finish" : "Next" : "Reveal Answer"}
            </button>
           
          </div>
        </div>
      </>
    </div>
  );
}

export default DiagnosticExam;
