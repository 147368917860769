import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import './css/Home.css';
import Dropdown from './Dropdown';
import SectionDropdown from './SectionDropdown';
import BeatLoader from 'react-spinners/BeatLoader';
import Logo from './assets/images/logo.png';
import UserDropdown from './UserDropdown';
import mixpanel from './mixpanel';
import ReferralCodeModal from './ReferralCodeModal';
import LockedExamModal from './LockedExamModal';
import { FaBars } from 'react-icons/fa';

const QUIZ_OF_THE_DAY_API_URL = "https://api.getmina.co/api/v1/panel/get-quiz-of-the-day";

function Home() {
    const navigate = useNavigate();
    const sections = useMemo(() => [
        { name: "Vocabulary", apiSection: "vocabulary" },
        { name: "Grammar", apiSection: "grammar" },
        { name: "Reading", apiSection: "reading" },
        // { name: "Quiz of the Day", apiSection: "quiz-of-the-day" },
    ], []);

    const levels = ["N1", "N2", "N3", "N4", "N5"];

    const [activeSection, setActiveSection] = useState(sections[0].name);
    const [selectedLevel, setSelectedLevel] = useState(() => {
        return localStorage.getItem('selectedLevel') || "N3";
    });
    const [sectionData, setSectionData] = useState([]);
    const [averageScore, setAverageScore] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [userStatus, setUserStatus] = useState(null);
    const [userData, setUserData] = useState(null);
    const [showReferralModal, setShowReferralModal] = useState(false);
    const [showLockedExamModal, setShowLockedExamModal] = useState(false);
    const [quizStatus, setQuizStatus] = useState(null);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleLockedExamClick = () => {
        setShowLockedExamModal(true);
    };

    const DIAGNOSTIC_EXAMS_API_URL = "https://api.getmina.co/api/v1/panel/diagnostic-exams";
    const fetchDiagnosticExams = useCallback(async () => {
        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch(`${DIAGNOSTIC_EXAMS_API_URL}/${selectedLevel}`, {
                headers: { 'Authorization': `Bearer ${jwt}` }
            });
            const diagnosticExams = await response.json();
            console.log("Diagnostic Exams:", diagnosticExams);
            navigate(`/StudyPlan`);
            // if (Array.isArray(diagnosticExams) && diagnosticExams.length > 0) {
            //     const incompleteExam = diagnosticExams.find(exam => !exam.completed);
            //     if (incompleteExam) {
            //         navigate(`/diagnostic-exam/${incompleteExam.exam_id}`);
            //     } else {
            //         navigate(`/StudyPlan`);
            //     }
            // } else {
            //     console.error("No diagnostic exams found or unexpected response format");
            // }
        } catch (error) {
            console.error("Error fetching diagnostic exams:", error);
            // Handle the error appropriately
        }
    }, [navigate, selectedLevel, DIAGNOSTIC_EXAMS_API_URL]);
    const fetchStudyPlanAccess = useCallback(async () => {
        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch("https://api.getmina.co/api/v1/panel/study-plan/access", {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    "Content-Type": "application/json"
                }
            });
            const data = await response.json();
            
            if (data.access) {
                fetchDiagnosticExams();
            } else {
                alert("Feature coming soon!");
            }
        } catch (error) {
            console.error("Error checking study plan access:", error);
            alert("An error occurred. Please try again later.");
        }
    }, [fetchDiagnosticExams]);

    const fetchExams = useCallback(async () => {
        const section = sections.find(s => s.name === activeSection);
        if (!section) return;

        try {
            setLoading(true);
            const jwt = localStorage.getItem('jwt');
            const response = await fetch("https://api.getmina.co/api/v1/panel/get-user-exams", {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ level: selectedLevel, section: section.apiSection })
            });
            const data = await response.json();
            const validData = Array.isArray(data) ? data : [];
            setSectionData(validData);
            console.log(data);
            setSectionData(Array.isArray(data) ? data : []);

                      // Extract unique categories
                      const allCategories = new Set(validData.flatMap(exam => {
                        if (Array.isArray(exam.categories)) {
                            return exam.categories;
                        } else {
                            return [];
                        }}));
                        setCategories(Array.from(allCategories));

            setLoading(false);

            const meResponse = await fetch("https://api.getmina.co/api/v1/auth/me", {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    "Content-Type": "application/json"
                },
            });

            const meData = await meResponse.json();

            if (meData.msg === "Token has expired") {
                localStorage.removeItem('jwt');
                window.location.reload();
            } else {
                setUserStatus(meData.status);
                setUserData(meData);
                mixpanel.identify(meData.user_id);
            }
        } catch (error) {
            console.error("Error fetching exams:", error);
            setError("Error fetching exams");
            setLoading(false);
        }
    }, [activeSection, selectedLevel, sections]);
 const toggleCategory = (category) => {
        setSelectedCategories(prev => 
            prev.includes(category)
                ? prev.filter(c => c !== category)
                : [...prev, category]
        );
    };

    const filteredExams = useMemo(() => {
        console.log("Filtering exams. Selected categories:", selectedCategories);
        console.log("Section data:", sectionData);

        if (selectedCategories.length === 0) return sectionData;
        
        return sectionData.filter(exam => {
            if (!exam) {
                console.warn("Invalid exam object:", exam);
                return false;
            }
            if (!Array.isArray(exam.categories)) {
                // If the exam doesn't have categories, it doesn't match our filter
                return false;
            }
            return selectedCategories.every(category => exam.categories.includes(category));
        });
    }, [sectionData, selectedCategories]);

    const fetchQuizStatus = useCallback(async () => {
        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch(QUIZ_OF_THE_DAY_API_URL, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ level: selectedLevel })
            });
            const data = await response.json();
            setQuizStatus(data.status);
        } catch (error) {
            console.error("Error fetching quiz of the day status:", error);
        }
    }, [selectedLevel]);

    const fetchAverageScore = useCallback(async (level, section) => {
        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch("https://api.getmina.co/api/v1/panel/get-average-score", {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ level, section: section.toLowerCase() })
            });
            const data = await response.json();
    
            const scoreData = data.find(d => d.level === level && d.section === section.toLowerCase());
    
            const newAverageScore = scoreData ? scoreData.average_score : 0;
            setAverageScore(newAverageScore);
        } catch (error) {
            console.error("Error fetching average score:", error);
            setAverageScore(0);
        }
    }, []);

    useEffect(() => {
        if (activeSection === "Quiz of the Day") {
            fetchQuizStatus();  
        } else {
            fetchExams();
        }
        fetchAverageScore(selectedLevel, activeSection);
    }, [fetchExams, fetchAverageScore, fetchQuizStatus, activeSection, selectedLevel]);

    useEffect(() => {
        localStorage.setItem('selectedLevel', selectedLevel);
    }, [selectedLevel]);

    const initiateCheckout = async (referralCode = '') => {
        mixpanel.track('Get full access', {});

        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch('https://api.getmina.co/api/v1/payment/create-checkout', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
                body: referralCode ? JSON.stringify({ referral_code: referralCode }) : JSON.stringify({})
            });

            const data = await response.json();

            if (response.ok) {
                const stripe = window.Stripe('pk_live_51PCLezBQK9NeVKEQWkVuPXASDt38HDl8kgGnYWKB9nWxfA8Atp2AeArCagP1UQue3FfktYrYcaISnOoOyOompsVt00Ps2Qs4ay');
                stripe.redirectToCheckout({ sessionId: data.sessionId });
            } else {
                throw new Error(data.error || 'Failed to initiate checkout');
            }
        } catch (error) {
            console.error('Failed to initiate checkout:', error.message);
        }
    };

    return (
        <div className="home">
            <div className="topbar">
                <div className="topbar-left">
                    <img src={Logo} alt="InnovateTech Logo" className="logo" />
                </div>
                <div className="topbar-right">
                    <div className="desktop-menu">
                        <NavLink to="/" className="topbar-link">
                            Dashboard
                        </NavLink>
                        <button onClick={fetchStudyPlanAccess} className="topbar-link">
                            Study Plan
                        </button>
                        {userStatus !== "active" && (
                            <button onClick={() => setShowReferralModal(true)} className="upgrade-button">
                                Get full access
                            </button>
                        )}
                    </div>
                    <FaBars className="menu-icon" onClick={toggleMobileMenu} />
                    {isMobileMenuOpen && (
                        <div className="mobile-menu">
                            <NavLink to="/" className="mobile-menu-item" onClick={() => setIsMobileMenuOpen(false)}>
                                Dashboard
                            </NavLink>
                            <button className="mobile-menu-item" onClick={() => {
                                fetchDiagnosticExams();
                                setIsMobileMenuOpen(false);
                            }}>
                                Study Plan
                            </button>
                            {userStatus !== "active" && (
                                <button onClick={() => setShowReferralModal(true)} className="mobile-menu-item">
                                    Get full access
                                </button>
                            )}
                        </div>
                    )}
                    {userData && <UserDropdown userData={userData} />}
                </div>
            </div>

            <div className="home-selectors">
                <div className="level-selector-container">
                    <div className="selector-title">Level</div>
                    <Dropdown
                        options={levels}
                        selected={selectedLevel}
                        onChange={setSelectedLevel}
                    />
                </div>
                <div className="average-score-container">
                    <div className="selector-title">Average Score</div>
                    <div className="average-score">{averageScore}%</div>
                </div>
            </div>

            {/* Section Titles for large screens */}
            <div className="section-titles">
                {sections.map((section, index) => (
                    <div key={index} className={`section-title ${activeSection === section.name ? 'active' : ''}`} onClick={() => setActiveSection(section.name)}>
                        {section.name}
                    </div>
                ))}
            </div>

            {/* Section Dropdown for small screens */}
            <div className="section-dropdown">
                <SectionDropdown
                    options={sections.map(section => section.name)}
                    selected={activeSection}
                    onChange={setActiveSection}
                />
            </div>
 <div className="category-boxes">
                {categories.map(category => (
                    <button
                        key={category}
                        className={`category-box ${selectedCategories.includes(category) ? 'selected' : ''}`}
                        onClick={() => toggleCategory(category)}
                    >
                        {category}
                    </button>
                ))}
            </div>
            {loading ? <div className="loader-container"><BeatLoader color="#3E00EF" /></div> : error ? <div>{error}</div> : (
                <div className="exams-container">
                    {activeSection === "Quiz of the Day" ? (
                        <div>
                            <Link 
                                to={quizStatus ? "#" : "/quiz-of-the-day"}
                                className={`exam-box ${quizStatus ? 'completed' : 'not-completed'}`}
                                onClick={(e) => {
                                    if (quizStatus) {
                                        e.preventDefault();
                                    }
                                }}
                            >
                                <div className="exam-info">
                                    <div className="exam-title">Quiz of the Day</div>
                                    <div className="exam-subtitle">{quizStatus ? "Completed" : "Take today's quiz!"}</div>
                                </div>
                            </Link>
                        </div>
                    ) : (
                        filteredExams.map((exam, examIndex) => (
                            <div
                                key={examIndex}
                                onClick={() => exam.isLocked ? handleLockedExamClick() : null}
                            >
                                <Link
                                    to={exam.completed ? (activeSection === "Mock Exam" ? `/review-mockexam/${exam.exam_id}` : `/review-exam/${exam.exam_id}`) : (exam.isLocked ? "#" : (activeSection === "Mock Exam" ? `/mockexam/${exam.exam_id}` : `/exam/${exam.exam_id}`))}
                                    className={`exam-box ${exam.completed ? 'completed' : 'not-completed'} ${exam.isLocked ? 'locked' : ''}`}
                                    onClick={() => {
                                        if (exam.completed) {
                                            mixpanel.track('Review Exam', {
                                                exam_id: exam.exam_id,
                                                exam_section: activeSection,
                                                exam_level: localStorage.getItem('selectedLevel'),
                                            });
                                        } else if (!exam.completed && !exam.isLocked) {
                                            mixpanel.track('New Exam Session', {
                                                exam_id: exam.exam_id,
                                                exam_section: activeSection,
                                                exam_level: localStorage.getItem('selectedLevel'),
                                            });
                                        }
                                    }}
                                >
                                    <div className="exam-info">
                                        <div className="exam-title">{`Exam ${examIndex + 1}`}</div>
                                        {exam.isLocked ? (
                                            <div className="exam-subtitle">Locked</div>
                                        ) : !exam.completed ? (
                                            <div className="exam-subtitle">Not completed</div>
                                        ) : (
                                            <div className="score-circle">
                                                {`${exam.score}%`}
                                            </div>
                                        )}
                                    </div>
                                </Link>
                            </div>
                        ))
                    )}
                </div>
            )}
            {showReferralModal && (
                <ReferralCodeModal
                    onClose={() => setShowReferralModal(false)}
                    onCheckout={initiateCheckout}
                />
            )}
            {showLockedExamModal && (
                <LockedExamModal
                    onClose={() => setShowLockedExamModal(false)}
                    onGetFullAccess={() => {
                        setShowLockedExamModal(false);
                        initiateCheckout();
                    }}
                />
            )}
        </div>
    );
}

export default Home;
