import React from 'react';
import './css/LockedExamModal.css';
import cancel from './assets/icons/cancel.png'; // Make sure the path to the cancel icon is correct

const LockedExamModal = ({ onClose, onGetFullAccess }) => {
    return (
        <div className="locked-exam-modal-overlay" onClick={onClose}>
            <div className="locked-exam-modal" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <img src={cancel} className="close-icon" onClick={onClose} alt="Close" />
                </div>
                <div className="modal-content">
                    <h2>Locked Exam</h2>
                    <p>You need to get full access to access this exam.</p>
                    <button className="get-full-access-button" onClick={onGetFullAccess}>
                        Get Full Access
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LockedExamModal;
