import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import BeatLoader from 'react-spinners/BeatLoader';
import Alert from '@mui/material/Alert';
import Mina from './assets/images/mina.png';
import './css/Login.css';
// import mixpanel from './mixpanel';  // Import the initialized Mixpanel instance

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const email = data.get('email');

    try {
      const response = await fetch('https://api.getmina.co/api/v1/auth/request-password-reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to request password reset');
      }

      setSuccessMessage('Password reset request sent. Please check your email.');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.message);
      setSuccessMessage('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs" className="login-container">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={Mina} alt="Mina Logo" className="mina" />
        <Typography component="h1" variant="h5" className="login-title">
          Enter Email
        </Typography>
        {errorMessage && (
          <Alert severity="error" sx={{ width: '100%', marginTop: 2 }}>
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" sx={{ width: '100%', marginTop: 2 }}>
            {successMessage}
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            className="login-input"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: '#3E00EF',
              color: 'white',
              borderRadius: '5px',
              padding: '10px 20px',
              '&:hover': {
                backgroundColor: '#272499',
              },
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              '&.Mui-disabled': {
                backgroundColor: '#c0c0c0',
                color: '#fff',
              }
            }}
            disabled={loading}
          >
            {loading ? <BeatLoader color="#fff" size={10} /> : 'Request Password Reset'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
