import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './css/Exam.css';
import BeatLoader from 'react-spinners/BeatLoader';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AskMina from './AskMina';
import mixpanel from './mixpanel';
import useMediaQuery from '@mui/material/useMediaQuery';

const SUBMIT_API_URL = "https://api.getmina.co/api/v1/panel/submit-exam";
const REPORT_ISSUE_API_URL = "https://api.getmina.co/api/v1/panel/report-issue";

function Exam() {
  const navigate = useNavigate();
  const location = useLocation();
  const { exam_id } = useParams();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [responses, setResponses] = useState([]);
  const [content, setContent] = useState([]);
  const [currentContentIndex, setCurrentContentIndex] = useState(location.state?.currentContentIndex || 0);
  const [userSelectedAnswer, setUserSelectedAnswer] = useState(null);
  const [revealAnswer, setRevealAnswer] = useState(false);
  const [isAnswerRevealed, setIsAnswerRevealed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hintReceived, setHintReceived] = useState(false);
  const [showAskMina, setShowAskMina] = useState(false);
  const [askMinaKey, setAskMinaKey] = useState(0);
  const [showReportModal, setShowReportModal] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState('');

  const examIdNumber = Number(exam_id);

  const hasFetchedQuestions = useRef(false);

  const fetchQuestions = useCallback(async () => {
    try {
      setLoading(true);
      const jwt = localStorage.getItem('jwt');
      const response = await fetch(`https://api.getmina.co/api/v1/panel/get-questions/${examIdNumber}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
      });
      const data = await response.json();
      console.log(data);
      setContent(data.map(question => ({ ...question, selectedAnswer: null, isAnswered: false })));
      setLoading(false);
    } catch (error) {
      setError("Error fetching questions");
      setLoading(false);
    }
  }, [examIdNumber]);

  


  useEffect(() => {
    if (!hasFetchedQuestions.current) {
      fetchQuestions();
      hasFetchedQuestions.current = true;
    }
  }, [fetchQuestions]);

  const handleAskMina = () => {
    setShowAskMina(true);
  };

  const handleSubmit = async () => {
    if (currentContentIndex === content.length - 1) {
      const requestBody = {
        exam_id: exam_id,
        responses: responses,
      };
      try {
        const jwt = localStorage.getItem('jwt');
        const response = await fetch(SUBMIT_API_URL, {
          method: "POST",
          headers: {
            'Authorization': `Bearer ${jwt}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to submit exam: ${response.status} ${errorText}`);
        }

        alert("Exam submitted successfully!");
        mixpanel.track('Exam Completed', {
          exam_id: exam_id,
         exam_type: "practice_exam"
        });
        console.log("the response:",response.text());
        navigate(`/review-exam/${exam_id}`);
      } catch (error) {
        console.error("Failed to submit exam:", error.message);
      }
    } else {
      setCurrentContentIndex(currentContentIndex + 1);
      setIsAnswerRevealed(false);
      setHintReceived(false); // Reset hint when moving to the next question
      setAskMinaKey(prevKey => prevKey + 1); // Force AskMina to re-render
     
    }
  
  };

  const handleAnswerSelection = (index) => {
    if (!isAnswerRevealed) {
      setUserSelectedAnswer(index);
      setRevealAnswer(false);

      const updatedContent = content.map((question, idx) =>
        idx === currentContentIndex ? { ...question, selectedAnswer: index } : question
      );
      setContent(updatedContent);
      const questionId = content[currentContentIndex]?.question_id;
      const selectedAnswer = content[currentContentIndex]?.choices.choices[index];

      const newResponses = responses.filter(response => response.question_id !== questionId);
      newResponses.push({ question_id: questionId, selected_answer: selectedAnswer });
      setResponses(newResponses);
    }
  };

  const handleRevealAnswer = () => {
 

    if (isAnswerRevealed) {
      const nextContentIndex = currentContentIndex + 1;
      if (nextContentIndex < content.length) {
        setCurrentContentIndex(nextContentIndex);
        setUserSelectedAnswer(content[nextContentIndex]?.selectedAnswer);
        setRevealAnswer(content[nextContentIndex]?.isAnswered);
        setIsAnswerRevealed(content[nextContentIndex]?.isAnswered);
        setHintReceived(false); // Reset hint when moving to the next question
        setAskMinaKey(prevKey => prevKey + 1); // Force AskMina to re-render
      }
      if (isAnswerRevealed && currentContentIndex === content.length - 1) {
        handleSubmit();
      }
    } else {
      setUserSelectedAnswer(content[currentContentIndex]?.selectedAnswer);
      setRevealAnswer(true);
      setIsAnswerRevealed(true);

      const updatedContent = content.map((question, idx) =>
        idx === currentContentIndex ? { ...question, isAnswered: true } : question
      );
      setContent(updatedContent);
      console.log(content[currentContentIndex],"SELECTECD ANSWER",selectedAnswer,)
      console.log("choices",content[currentContentIndex].choices.choices[selectedAnswer])
      mixpanel.track('Reveal Answer', {
        question: content[currentContentIndex]?.question,
        question_id: content[currentContentIndex]?.question_id,
        question_number: currentContentIndex + 1,
        selected_answer: content[currentContentIndex].choices.choices[selectedAnswer],
        correct_answer: content[currentContentIndex]?.answer,
        level: localStorage.getItem('selectedLevel'),

      });
    }
  };

  const handlePreviousQuestion = () => {
    if (currentContentIndex > 0) {
      const previousContentIndex = currentContentIndex - 1;
      setCurrentContentIndex(previousContentIndex);
      setUserSelectedAnswer(content[previousContentIndex]?.selectedAnswer);
      setRevealAnswer(true);
      setIsAnswerRevealed(true);
      setHintReceived(false); // Reset hint when moving to the previous question
    }
  };

  const handleBackButtonClick = () => {
    mixpanel.track('Cancel Exam', {
      exam_id: exam_id,
      question_id: content[currentContentIndex]?.question_id,
      question_number: currentContentIndex + 1,
    });
    navigate('/');
  };

  const formatText = (text) => {
    if (!text || text === '0') return ''; // Treat 0 as an empty string

    // Use a regular expression to split by spaces unless they are within parentheses
    return text.split(/(?<!\S)(\s{2,})(?!\S)(?!.*\))/g).map((part, index) => (
      <span key={index}>
        {part.trim().length === 0 ? <br /> : part}
      </span>
    ));
  };

  const handleReportIssue = () => {
    setShowReportModal(true);
  };

  const handleSubmitIssue = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await fetch(REPORT_ISSUE_API_URL, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${jwt}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          question_id: content[currentContentIndex]?.question_id,
          issue_message: selectedIssue,
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to submit issue: ${response.status} ${errorText}`);
      }

      alert("Issue reported successfully!");
      setShowReportModal(false);
      setSelectedIssue('');
    } catch (error) {
      console.error("Failed to submit issue:", error.message);
    }
  };

  const closeModal = (e) => {
    if (e.target.className === "report-issue-overlay") {
      setShowReportModal(false);
    }
  };

  if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;

  if (error) {
    return <div>{error}</div>;
  }

  if (content.length === 0 || !content[currentContentIndex]) {
    return <div>No content available</div>;
  }

  const questionNumber = currentContentIndex + 1;
  const { script, question, instruction, choices, answer, hint, explanation, selectedAnswer, isAnswered } = content[currentContentIndex];

  return (
    <div className="Exam">
      <>
        <div className={`upper-bar `}>
          <div onClick={handleBackButtonClick} aria-label="back" className="back-button upper-left">
            <ArrowBackIosNewIcon />
            Back
          </div>
          <div className="upper-center">{`Practice Exam`}</div>
          <div className="upper-right">Question {questionNumber} / {content.length} </div>
        </div>
        
        <div className="content">
          
          {isSmallScreen ? (
            <>
      
           
              
              <div className="right-section">
                
                {instruction && <p>{formatText(instruction)}</p>}
                
                <br></br>
                {script && (
                  <>
                    <div className="passage-box">
                      {formatText(script)}
                    </div>
                  </>
                )}
                <br></br>

                <div className="question-answer">
                  <div className="question-text">{formatText(question)}</div>
                  <div className="answer-section">
                    {choices.choices.map((choice, index) => (
                      <div
                        key={index}
                        className={`answer-option ${selectedAnswer === index ? 'selected' : ''} ${revealAnswer ? (choice === answer ? 'correct' : (selectedAnswer === index ? 'incorrect' : '')) : ''}`}
                        onClick={() => !isAnswered && handleAnswerSelection(index)}
                      >
                        {choice}
                      </div>
                      
                    ))}
                  </div>
                  {hintReceived && hint && (
                    <div className="hint-box">
                      <strong>Hint:</strong> {hint}
                    </div>
                  )}
                  {isAnswerRevealed && explanation && (
                    <div className="explanation-box">
                      <strong>Explanation:</strong> {explanation}
                    </div>
                  )}
                </div>
                
              </div>
            </>
          ) : (
            <>

              <div className="left-section">
              <div className='question-title'> Question {questionNumber}</div>
                {instruction && <p>{formatText(instruction)}</p>}
                {script && (
                  <>
                    <div className="passage-box">
                      {formatText(script)}
                    </div>
                  </>
                )}
                <div className="question-answer">
                  <div className="question-text">{formatText(question)}</div>
                  <div className="answer-section">
                    {choices.choices.map((choice, index) => (
                      <div
                        key={index}
                        className={`answer-option ${selectedAnswer === index ? 'selected' : ''} ${revealAnswer ? (choice === answer ? 'correct' : (selectedAnswer === index ? 'incorrect' : '')) : ''}`}
                        onClick={() => !isAnswered && handleAnswerSelection(index)}
                      >
                        {choice}
                      </div>
                    ))}
                  </div>
                  {hintReceived && hint && (
                    <div className="hint-box">
                      <strong>Hint:</strong> {hint}
                    </div>
                  )}
                  {isAnswerRevealed && explanation && (
                    <div className="explanation-box">
                      <strong>Explanation:</strong> {explanation}
                    </div>
                  )}
                </div>
            
              </div>
              <div className="divider"></div>
              <div className="right-section">
                <AskMina
                  hint={hint}
                  key={askMinaKey}
                  questionId={content[currentContentIndex]?.question_id}
                  questionnumber={currentContentIndex + 1}
                  initialChatHistory={[]}
                  onClose={() => setShowAskMina(false)}
                />
                {showAskMina && (
                  <AskMina
                    hint={hint}
                    key={askMinaKey}
                    questionId={content[currentContentIndex]?.question_id}
                    questionnumber={currentContentIndex + 1}
                    initialChatHistory={[]}
                    onClose={() => setShowAskMina(false)}
                  />
                )}
              </div>
            </>
          )}
        </div>
        <div className={`lower-bar`}>

   <div className="lower-left">

   <button className="report-issue-button" onClick={handleReportIssue}>Report Issue</button>
   </div>
  <div className="lower-right">
    <button className="previous-button" onClick={handlePreviousQuestion}>Previous</button>
    <button className="reveal-answer-button" onClick={handleRevealAnswer} disabled={userSelectedAnswer === null}>
      {isAnswerRevealed ? currentContentIndex === content.length - 1 ? "Finish" : "Next" : "Reveal Answer"}
    </button>
  </div>
</div>

        {isSmallScreen && !showAskMina && (
          <button className="floating-ask-mina-button" onClick={handleAskMina}>Ask Mina</button>
        )}
      </>
      {showAskMina && isSmallScreen && (
        <div className="askmina-overlay">
          <AskMina
            questionId={content[currentContentIndex]?.question_id}
            questionnumber={currentContentIndex + 1}
            initialChatHistory={[]}
            onClose={() => setShowAskMina(false)}
          />
        </div>
      )}
      {showReportModal && (
        <div className="report-issue-overlay" onClick={closeModal}>
          <div className="report-issue-modal">
            <h2>Report a mistake</h2>
            <hr />
            <p>What's wrong?</p>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="None of the options is correct"
                  checked={selectedIssue === "None of the options is correct"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                />
                None of the options is correct
              </label>
            </div>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="Question has incorrect or missing data"
                  checked={selectedIssue === "Question has incorrect or missing data"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                />
                Question has incorrect or missing data
              </label>
            </div>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="Questions/Options/Infographics are not visible properly or missing"
                  checked={selectedIssue === "Questions/Options/Infographics are not visible properly or missing"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                />
                Questions/Options/Infographics are not visible properly or missing
              </label>
            </div>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="Question or option has a typo"
                  checked={selectedIssue === "Question or option has a typo"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                />
                Question or option has a typo
              </label>
            </div>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="The question is confusing or unclear"
                  checked={selectedIssue === "The question is confusing or unclear"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                />
                The question is confusing or unclear
              </label>
            </div>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="Other"
                  checked={selectedIssue === "Other"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                />
                Other
              </label>
            </div>
            <hr />
            <button className="submit-issue-button" onClick={handleSubmitIssue}>Submit Issue</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Exam;
